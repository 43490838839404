/* eslint-disable @typescript-eslint/camelcase */

import { CompanyOnboarding } from "./company-onboarding";
import { PrimaryContactOnboarding } from "./primary-contact-onboarding";

export class OnboardingApplication {
  public company: CompanyOnboarding;
  public company_primary_contact: PrimaryContactOnboarding;

  constructor(
    company: CompanyOnboarding,
    company_primary_contact: PrimaryContactOnboarding
  ) {
    this.company = company;
    this.company_primary_contact = company_primary_contact;
  }
}
