












import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class IconInfoBlock extends Vue {
  @Prop() title!: string;
  @Prop() caption!: string;
  @Prop() icon!: string;
}
