/* eslint-disable @typescript-eslint/camelcase */

export class PrimaryContactOnboarding {
  public name: string;
  public email: string;
  public phone?: string;
  public phone_type?: string;

  constructor(
    name: string,
    email: string,
    phone?: string,
    phone_type?: string
  ) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.phone_type = phone_type;
  }
}
