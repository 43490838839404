var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"persistent":"","width":"50%"},model:{value:(_vm.isModalDisplayed),callback:function ($$v) {_vm.isModalDisplayed=$$v},expression:"isModalDisplayed"}},[_c('div',{staticClass:"form-wrapper"},[_c('v-card',{staticClass:"payment-application-form"},[(_vm.isInitiationInProgress)?_c('v-progress-linear',{attrs:{"color":"primary"}}):[_c('h2',[_vm._v("Register for Edenred")]),_c('h4',{staticClass:"mb-4"},[_vm._v(" Enter your information to create an Edenred account ")]),_c('v-divider'),_c('v-form',{ref:"payments-onboarding-form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'First Name is a required field'; },
                function (v) { return !/^\s*$/.test(v) || 'First Name is a required field'; }
              ],"label":"First Name","required":"","outlined":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'Last Name is a required field'; },
                function (v) { return !/^\s*$/.test(v) || 'Last Name is a required field'; }
              ],"label":"Last Name","required":"","outlined":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'Email Address is a required field'; },
                function (v) { return /.+@.+\..+/.test(v) ||
                  'Please enter a valid email address (e.g., \'user@example.com\')'; }
              ],"label":"Email Address","required":"","outlined":""},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}}),_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'Company Name is a required field'; },
                function (v) { return !/^\s*$/.test(v) || 'Company Name is a required field'; }
              ],"label":"Company Name","required":"","outlined":""},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('v-text-field',{attrs:{"rules":[
                function (v) { return !!v || 'Tax ID is a required field'; },
                function (v) { return !/^\s*$/.test(v) || 'Tax ID is a required field'; }
              ],"label":"Tax ID","required":"","outlined":""},model:{value:(_vm.taxID),callback:function ($$v) {_vm.taxID=$$v},expression:"taxID"}})],1),_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"text-none action-confirmation-button",attrs:{"tile":"","disabled":!_vm.isFormValid || _vm.isActionInProgress},on:{"click":_vm.registerForPayments}},[_vm._v(" Register ")]),_c('v-btn',{staticClass:"text-none action-cancel-button",attrs:{"tile":"","disabled":_vm.isActionInProgress},on:{"click":_vm.cancelRegistration}},[_vm._v(" Cancel ")])],1)]],2)],1)]),_c('div',{staticClass:"title-block"},[_c('h1',[_vm._v("Apply for")]),_c('h1',[_vm._v("Payments Automation")]),_c('v-divider',{staticClass:"page-divider"}),_c('p',[_vm._v(" Transform your accounts payable into a profit center by automating payments, preventing fraud, and enhancing efficiency. ")]),_c('primary-button',{staticClass:"mb-8 mt-6",attrs:{"buttonText":"Apply Now","handleClick":_vm.initiatePaymentsApplication,"disabled":_vm.isApplyNowButtonDisabled}})],1),_c('learn-about-payments')],1)}
var staticRenderFns = []

export { render, staticRenderFns }