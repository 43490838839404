















import { Component } from "vue-property-decorator";
import { paymentCustomerService } from "@/services/payments/payment-customer.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import ProductApplication from "@/components/payments/upgrade/ProductApplication.vue";
import ApplicationLockedForEditing from "@/components/payments/upgrade/ApplicationLockedForEditing.vue";
import ApplicationUnderReview from "@/components/payments/upgrade/ApplicationUnderReview.vue";
import ProductActive from "@/components/payments/upgrade/ProductActive.vue";
import { InitiateOnboardingResponse } from "@/models/payments/customers/onboarding/initiate-onboarding-response";

@Component({
  components: {
    "product-application": ProductApplication,
    "application-locked-for-editing": ApplicationLockedForEditing,
    "application-under-review": ApplicationUnderReview,
    "product-active": ProductActive
  }
})
export default class UpgradeToPayments extends UserRolesMixin {
  // reactive class properties

  private isLoading = false;
  private isOnboardingFormCompleted = false;
  private onboardingFormLockedAt = "";
  private userInitiatingOnboarding = "";

  // computed properties

  private get activeComponent() {
    if (this.userStore.getIsPaymentsCustomer) {
      return "product-active";
    } else if (this.isOnboardingFormCompleted) {
      return "application-under-review";
    } else if (this.userInitiatingOnboarding?.length > 0) {
      return "application-locked-for-editing";
    } else {
      return "product-application";
    }
  }

  private get isApplicationLockedForEditing(): boolean {
    return (
      !this.isOnboardingFormCompleted &&
      this.userInitiatingOnboarding?.length > 0 &&
      this.userEmailAddress != this.userInitiatingOnboarding
    );
  }

  // lifecycle methods

  async created() {
    this.isLoading = true;
    await this.checkPaymentsCustomerStatus();
    this.isLoading = false;
  }

  // methods

  async checkPaymentsCustomerStatus() {
    const customerStatus = await paymentCustomerService.getStatus();
    this.userStore.setIsPaymentsCustomer(customerStatus?.is_active ?? false);
    this.isOnboardingFormCompleted =
      customerStatus?.onboarding_form_status?.onboarding_form_completed ??
      false;
    this.onboardingFormLockedAt =
      customerStatus?.onboarding_form_status?.onboarding_form_locked_at;
    this.userInitiatingOnboarding =
      customerStatus?.onboarding_form_status?.user_requesting_onboarding;
  }

  handleInitiationStateUpdate(response: InitiateOnboardingResponse) {
    if (response?.onboarding_form_status) {
      this.isOnboardingFormCompleted =
        response.onboarding_form_status?.onboarding_form_completed;
      this.onboardingFormLockedAt =
        response.onboarding_form_status?.onboarding_form_locked_at;
      this.userInitiatingOnboarding =
        response.onboarding_form_status?.user_requesting_onboarding;
    }
  }

  handleOnboardingApplicationCompleted() {
    this.isOnboardingFormCompleted = true;
  }
}
