












import { Vue, Component } from "vue-property-decorator";
import LearnAboutPayments from "./LearnAboutPayments.vue";

@Component({
  components: {
    "learn-about-payments": LearnAboutPayments
  }
})
export default class ApplicationUnderReview extends Vue {}
