/* eslint-disable @typescript-eslint/camelcase */

export class CompanyOnboarding {
  public legal_name: string;
  public tax_id: string;

  constructor(legal_name: string, tax_id: string) {
    this.legal_name = legal_name;
    this.tax_id = tax_id;
  }
}
