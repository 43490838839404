



























import { Vue, Component, Prop } from "vue-property-decorator";
import { utils } from "@/utils/okta-utils";

@Component
export default class ApplicationLockedForEditing extends Vue {
  // props

  @Prop() isApplicationLockedForEditing!: boolean;
  @Prop() onboardingFormLockedAt!: string;
  @Prop() userInitiatingOnboarding!: string;

  // methods

  private setLocalDateTime(utcDate: string) {
    return utcDate ? utils.utcToLocalDateString(new Date(utcDate)) : "";
  }
}
