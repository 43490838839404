















































































































import { Vue, Component } from "vue-property-decorator";
import LearnAboutPayments from "./LearnAboutPayments.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import { paymentCustomerService } from "@/services/payments/payment-customer.service";
import options from "@/shared/constants/toast-options";
import { InitiateOnboardingResponse } from "@/models/payments/customers/onboarding/initiate-onboarding-response";
import { CancelOnboardingResponse } from "@/models/payments/customers/onboarding/cancel-onboarding-response";
import { OnboardingApplication } from "@/models/payments/customers/onboarding/onboarding-application";
import { PrimaryContactOnboarding } from "@/models/payments/customers/onboarding/primary-contact-onboarding";
import { CompanyOnboarding } from "@/models/payments/customers/onboarding/company-onboarding";
import { CompleteOnboardingResponse } from "@/models/payments/customers/onboarding/complete-onboarding-response";

@Component({
  components: {
    "learn-about-payments": LearnAboutPayments,
    "primary-button": PrimaryButton
  }
})
export default class ProductApplication extends Vue {
  // reactive class properties

  private isApplyNowButtonDisabled = false;
  private isModalDisplayed = false;
  private isFormValid = false;
  private isActionInProgress = false;
  private isInitiationInProgress = false;

  private firstName = "";
  private lastName = "";
  private emailAddress = "";
  private companyName = "";
  private taxID = "";

  // methods

  async validateFormFields() {
    (this.$refs["payments-onboarding-form"] as any)?.validate();
  }

  handleApplicationModal() {
    this.isModalDisplayed = !this.isModalDisplayed;
  }

  async initiatePaymentsApplication() {
    this.isInitiationInProgress = true;
    this.isApplyNowButtonDisabled = true;
    this.handleApplicationModal();
    // make request to payments API to lock the onboarding form
    const response: InitiateOnboardingResponse = await paymentCustomerService.initiateOnboardingApplication();
    if (response?.code == 409) {
      // emit event for parent component to load the locked for editing content instead
      this.$emit("updateInitialState", response);
    } else if (response?.code != 200) {
      // display error message and close modal
      this.$toasted.show(
        "<p>" +
          (response?.message?.join(". ") ??
            "An error occured. Please try again later") +
          "<p>",
        options.ERROR_OPTIONS
      );
      this.handleApplicationModal();
    }
    // when code == 200, user successfully locked the form for editing and form content will load
    this.isInitiationInProgress = false;
    this.isApplyNowButtonDisabled = false;
  }

  async resetForm() {
    // clears the form field values and validation states
    (this.$refs["payments-onboarding-form"] as any)?.reset();
  }

  async registerForPayments() {
    this.isActionInProgress = true;
    await this.validateFormFields();
    if (this.isFormValid) {
      const application: OnboardingApplication = new OnboardingApplication(
        new CompanyOnboarding(this.companyName, this.taxID),
        new PrimaryContactOnboarding(
          this.firstName
            .trim()
            .concat(" ")
            .concat(this.lastName.trim()),
          this.emailAddress
        )
      );
      const response: CompleteOnboardingResponse = await paymentCustomerService.submitOnboardingApplication(
        application
      );
      if (response?.code == 200) {
        // application successful, close modal and emit event to load thank you screen
        this.$emit("updateCompletedState");
        this.handleApplicationModal();
        // open Edenred Pay URL in new tab
        if (response?.provider_response?.application_url) {
          window.open(response.provider_response.application_url, "_blank");
        }
      } else {
        // display error message
        this.$toasted.show(
          "There was an error processing your application. Please try again later.",
          options.ERROR_OPTIONS
        );
      }
    }
    this.isActionInProgress = false;
  }

  async cancelRegistration() {
    this.isActionInProgress = true;
    // make request to unlock the onboarding form for editing
    const response: CancelOnboardingResponse = await paymentCustomerService.cancelOnboardingApplication();
    if (response?.code == 200) {
      // successful, close modal and reset form
      this.handleApplicationModal();
      await this.resetForm();
    } else {
      // unsuccessful, display error message
      this.$toasted.show(
        "An error occurred. Please try again later.",
        options.ERROR_OPTIONS
      );
    }
    this.isActionInProgress = false;
  }
}
