










































import { Vue, Component } from "vue-property-decorator";
import IconInfoBlock from "./IconInfoBlock.vue";

@Component({
  components: {
    "icon-info-block": IconInfoBlock
  }
})
export default class LearnAboutPayments extends Vue {}
